


















































































































import Vue from "vue";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import StripeCard from "@/components/billing/StripeCard.vue";
import StripeSEPA from "@/components/billing/StripeSEPA.vue";
import CreditCard from "@/components/billing/CreditCard.vue";
import { mapState } from "vuex";
import isPast from "date-fns/isPast";
import { ActionTypes } from "@/store/action-types";
import { confirmModal } from "@/modal";
import { BIconCheck, BIconThreeDotsVertical, BIconTrash } from "bootstrap-vue";

const TODAY = new Date();
export default Vue.extend({
  components: {
    LayoutWithPageHeader,
    CreditCard,
    StripeSEPA,
    StripeCard,
    BIconCheck,
    BIconThreeDotsVertical,
    BIconTrash
  },
  data() {
    return {
      today: TODAY,
      cardFields: ["Type", "Expiration date", "Contact", "default", "Actions"],
      addOptionModalId: "add-option-modal",
      addOptionModalSepaId: "add-sepa-option-modal",
      addOptionForm: {
        name: "",
        number: null,
        expirationDate: {
          month: TODAY.getMonth() + 1,
          year: TODAY.getFullYear()
        },
        crypto: null,
        isDefault: true
      }
    };
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_PAYMENT_METHODS);
  },
  computed: {
    ...mapState({
      cards: state => state.paymentMethod.paymentMethodList
    })
  },
  methods: {
    isPast,
    isExpired(year: number, month: number) {
      return this.isPast(new Date(year, month));
    },
    openModal() {
      this.$bvModal.show(this.addOptionModalId);
    },
    openSEPAModal() {
      this.$bvModal.show(this.addOptionModalSepaId);
    },
    closeModal() {
      this.$bvModal.hide(this.addOptionModalId);
    },
    closeSEPAModal() {
      this.$bvModal.hide(this.addOptionModalSepaId);
    },

    cancelSetupIndent() {
      this.$storeTyped.dispatch(ActionTypes.CANCEL_SETUP_INDENT);
    },
    onDeleteOption(key: string) {
      const confirmFn = () =>
        this.$storeTyped.dispatch(ActionTypes.DELETE_PAYMENT_METHOD, key);

      confirmModal(
        this,
        confirmFn,
        " Please confirm you want to remove this payment option.",
        "Payment option deletion"
      );
    },
    markAsDefault(key: string) {
      this.$storeTyped.dispatch(ActionTypes.MARK_METHOD_AS_DEFAULT, key);
    }
  }
});
