




































import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import { errorToast, successToast } from "@/toast";
import Loader from "@/components/layout/Loader.vue";
import mixins from "vue-typed-mixins";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes as PaymentMethodMutationType } from "@/store/modules/paymentMethod";
import {
  Stripe,
  StripeCardElement,
  StripeConstructor
} from "@stripe/stripe-js";
import { SetupIntentStatus } from "@/enum";

export default mixins(formMixin).extend({
  components: { Loader },
  data() {
    return {
      cardError: null as string | null,
      submitting: false,
      stripe: null as Stripe | null,
      cardElement: null as StripeCardElement | null,
      isStripeLoading: false
    };
  },
  computed: {
    ...mapState({
      currentOrg: state => state.organization.organization,
      setupIntent: state => state.paymentMethod.stripeSetupIntent,
      paymentMethods: state => state.paymentMethod.paymentMethodList
    }),
    stripeRef() {
      return window.Stripe;
    }
  },
  async mounted() {
    await this.$storeTyped.dispatch(ActionTypes.CREATE_SETUP_INDENT);

    // Set the watcher after mount as it depends on stripeRef but but still needs to access the DOM
    this.$watch("stripe", {
      handler(stripe: Stripe | null) {
        this.mountStripeCard(stripe);
      },
      immediate: true
    });
  },
  watch: {
    stripeRef: {
      handler(stripeConstructor: StripeConstructor) {
        if (!process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY) {
          throw new Error(
            `Environment error: Given VUE_APP_STRIPE_PUBLISHABLE_API_KEY was not defined in ${process.env.NODE_ENV}`
          );
        }
        this.stripe = stripeConstructor(
          process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY,
          {
            apiVersion: "2020-08-27"
          }
        );
      },
      immediate: true
    }
  },
  methods: {
    errorToast,
    successToast,
    async mountStripeCard(stripe: Stripe | null) {
      if (stripe) {
        this.isStripeLoading = true;
        const elements = stripe.elements();
        this.cardElement = elements.create("card", {
          style: {
            base: {
              fontFamily: "Cerebri Sans, sans-serif",
              "::placeholder": {
                color: "#c4b5db"
              }
            }
          }
        });

        this.cardElement.on("ready", () => {
          this.isStripeLoading = false;
          this.cardElement?.focus();
        });

        this.cardElement.on("change", e => {
          this.cardError = e.error ? e.error.message : null;
        });

        this.cardElement.mount(this.$refs.stripeCard);
      }
    },
    closeCard() {
      this.$emit("close");
    },
    async confirmCardDetails() {
      if (
        this.stripe &&
        this.setupIntent &&
        this.cardElement &&
        !this.submitting
      ) {
        this.submitting = true;
        try {
          const { setupIntent, error } = await this.stripe.confirmCardSetup(
            this.setupIntent.clientSecret,
            {
              /* eslint-disable @typescript-eslint/camelcase */
              payment_method: {
                card: this.cardElement
              }
            }
          );
          if (error && error.message) {
            this.errorToast(this, error.message);
          } else {
            if (
              setupIntent &&
              setupIntent.status === SetupIntentStatus.succeeded
            ) {
              await this.markFirstPaymentAsDefault(setupIntent.payment_method);
              this.$storeTyped.commit(
                PaymentMethodMutationType.SET_SETUP_INTENT,
                null
              );
              this.successToast(
                this,
                "Your payment method was successfully saved."
              );
              this.$storeTyped.dispatch(ActionTypes.FETCH_PAYMENT_METHODS);
              this.closeCard();
            }
          }
        } finally {
          this.submitting = false;
        }
      }
    },

    markFirstPaymentAsDefault(key: string | null) {
      if (this.paymentMethods && this.paymentMethods.length === 0 && key) {
        return this.$storeTyped.dispatch(
          ActionTypes.MARK_METHOD_AS_DEFAULT,
          key
        );
      }
    }
  }
});
