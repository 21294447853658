











import { assetDictionary } from "@/enum";
import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      default: "SEPA"
    },
    last4: { type: Number }
  },
  data() {
    return {
      assetDictionary
    };
  },
  computed: {
    number(): string {
      const res = this.last4 ? this.last4 : "xxxx";
      return `****-${res}`;
    }
  }
});
