


































































import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import { errorToast, successToast } from "@/toast";
import Loader from "@/components/layout/Loader.vue";
import mixins from "vue-typed-mixins";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes as PaymentMethodMutationType } from "@/store/modules/paymentMethod";
import {
  Stripe,
  StripeIbanElement,
  StripeConstructor
} from "@stripe/stripe-js";
import { SetupIntentStatus } from "@/enum";

export default mixins(formMixin).extend({
  components: { Loader },
  data() {
    return {
      sepaError: null as string | null,
      submitting: false,
      stripe: null as Stripe | null,
      SepaElement: null as StripeIbanElement | null,
      isStripeLoading: false,
      accountholder_name: "",
      accountholder_email: ""
    };
  },
  computed: {
    ...mapState({
      currentOrg: state => state.organization.organization,
      setupIntent: state => state.paymentMethod.stripeSetupIntent,
      paymentMethods: state => state.paymentMethod.paymentMethodList
    }),
    stripeRef() {
      return window.Stripe;
    }
  },
  async mounted() {
    await this.$storeTyped.dispatch(ActionTypes.CREATE_SETUP_INDENT);

    // Set the watcher after mount as it depends on stripeRef but but still needs to access the DOM
    this.$watch("stripe", {
      handler(stripe: Stripe | null) {
        this.mountStripeSepa(stripe);
      },
      immediate: true
    });
  },
  watch: {
    stripeRef: {
      handler(stripeConstructor: StripeConstructor) {
        if (!process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY) {
          throw new Error(
            `Environment error: Given VUE_APP_STRIPE_PUBLISHABLE_API_KEY was not defined in ${process.env.NODE_ENV}`
          );
        }
        this.stripe = stripeConstructor(
          process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY,
          {
            apiVersion: "2020-08-27"
          }
        );
      },
      immediate: true
    }
  },
  methods: {
    errorToast,
    successToast,
    async mountStripeSepa(stripe: Stripe | null) {
      if (stripe) {
        this.isStripeLoading = true;
        const elements = stripe.elements();
        this.SepaElement = elements.create("iban", {
          supportedCountries: ["SEPA"],
          style: {
            base: {
              fontFamily: "Cerebri Sans, sans-serif",
              "::placeholder": {
                color: "#c4b5db"
              }
            }
          }
        });

        this.SepaElement.on("ready", () => {
          this.isStripeLoading = false;
          this.SepaElement?.focus();
        });

        this.SepaElement.on("change", e => {
          this.sepaError = e.error ? e.error.message : null;
        });

        this.SepaElement.mount(this.$refs.stripeSepa);
      }
    },
    closeSepa() {
      this.$emit("close");
    },
    async confirmSEPADetails() {
      if (
        this.stripe &&
        this.setupIntent &&
        this.SepaElement &&
        !this.submitting
      ) {
        this.submitting = true;
        try {
          const {
            setupIntent,
            error
          } = await this.stripe.confirmSepaDebitSetup(
            this.setupIntent.clientSecret,
            {
              /* eslint-disable @typescript-eslint/camelcase */
              payment_method: {
                sepa_debit: this.SepaElement,
                billing_details: {
                  name: this.accountholder_name,
                  email: this.accountholder_email
                }
              }
            }
          );
          if (error && error.message) {
            this.errorToast(this, error.message);
          } else {
            if (
              setupIntent &&
              setupIntent.status === SetupIntentStatus.succeeded
            ) {
              await this.markFirstPaymentAsDefault(setupIntent.payment_method);
              this.$storeTyped.commit(
                PaymentMethodMutationType.SET_SETUP_INTENT,
                null
              );
              this.successToast(
                this,
                "Your payment method was successfully saved."
              );
              this.$storeTyped.dispatch(ActionTypes.FETCH_PAYMENT_METHODS);
              this.closeSepa();
            }
          }
        } finally {
          this.submitting = false;
        }
      }
    },

    markFirstPaymentAsDefault(key: string | null) {
      if (this.paymentMethods && this.paymentMethods.length === 0 && key) {
        return this.$storeTyped.dispatch(
          ActionTypes.MARK_METHOD_AS_DEFAULT,
          key
        );
      }
    }
  }
});
